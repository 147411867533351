<template>
  <div>
    <base-profile />
    <div class="container-profile pb-5">
      <div class="container-profile-page">
        <div v-if="token" class="container-profile-mis-datos">
          <div class="col-12 col-md-8 container-data-profile mt-5">
            <div v-if="dataUser">
              <div class="mt-5">
                <div class="col-12 col-md-6 px-0 mx-auto">
                  <p class="mb-3 fs-6">
                    Por favor ingresa la contraseña actual
                  </p>
                </div>
                <div class="col-12 col-md-6 position-relative mx-auto">
                  <input
                    v-model="passwordNow"
                    class="payment-input input-login mb-4 pe-4"
                    placeholder="Contraseña actual"
                    :type="typePass ? 'password' : 'text'"
                  />
                  <span v-if="validatePassword" class="error-inputs"
                    >Este campo es obligatorio</span
                  >
                  <span v-if="passwordNow" class="newlatter-error-input"
                    >Contraseña actual</span
                  >
                  <svg
                    class="show-password"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.207"
                    height="23.414"
                    viewBox="0 0 24.207 23.414"
                    @click="typePass = !typePass"
                  >
                    <g
                      id="Grupo_5755"
                      data-name="Grupo 5755"
                      transform="translate(-407 -937.793)"
                    >
                      <g
                        id="Grupo_5027"
                        data-name="Grupo 5027"
                        transform="translate(-1080.5 601.643)"
                      >
                        <path
                          id="Trazado_2344"
                          data-name="Trazado 2344"
                          d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                          transform="translate(0)"
                          fill="none"
                          stroke="#949494"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <circle
                          id="Elipse_582"
                          data-name="Elipse 582"
                          cx="4.304"
                          cy="4.304"
                          r="4.304"
                          transform="translate(1495.196 343.37)"
                          fill="none"
                          stroke="#949494"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                      <line
                        v-if="!typePass"
                        id="Línea_1365"
                        data-name="Línea 1365"
                        y1="22"
                        x2="22"
                        transform="translate(408.5 938.5)"
                        fill="none"
                        stroke="#949494"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
                <div
                  class="col-12 col-md-7 px-0 position-relative mx-auto pt-1"
                >
                  <hr />
                </div>
                <div class="col-12 col-md-6 px-0 position-relative mx-auto">
                  <p class="mb-3">Ingresa nueva contraseña</p>
                </div>
                <div class="col-12 col-md-6 position-relative mx-auto">
                  <input
                    v-model="newPassword"
                    class="payment-input input-login mb-4 pe-4"
                    placeholder="Nueva contraseña"
                    :type="typePassConfirm ? 'password' : 'text'"
                  />
                  <span v-if="validateUpdatePassword" class="error-inputs"
                    >mínimo 8 caracteres</span
                  >
                  <span
                    v-if="newPassword"
                    class="newlatter-error-input"
                    style="left: 1.5rem"
                    >Nueva contraseña</span
                  >
                  <svg
                    class="show-password"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.207"
                    height="23.414"
                    viewBox="0 0 24.207 23.414"
                    @click="typePassConfirm = !typePassConfirm"
                  >
                    <g
                      id="Grupo_5755"
                      data-name="Grupo 5755"
                      transform="translate(-407 -937.793)"
                    >
                      <g
                        id="Grupo_5027"
                        data-name="Grupo 5027"
                        transform="translate(-1080.5 601.643)"
                      >
                        <path
                          id="Trazado_2344"
                          data-name="Trazado 2344"
                          d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                          transform="translate(0)"
                          fill="none"
                          stroke="#949494"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <circle
                          id="Elipse_582"
                          data-name="Elipse 582"
                          cx="4.304"
                          cy="4.304"
                          r="4.304"
                          transform="translate(1495.196 343.37)"
                          fill="none"
                          stroke="#949494"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </g>
                      <line
                        v-if="!typePassConfirm"
                        id="Línea_1365"
                        data-name="Línea 1365"
                        y1="22"
                        x2="22"
                        transform="translate(408.5 938.5)"
                        fill="none"
                        stroke="#949494"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
                <div class="col-12 col-md-6 px-0 mx-auto mb-4">
                  <p class="mb-3 text-center">
                    Recuerda que la contraseña debe tener al menos 8 caracteres
                  </p>
                </div>
                <div class="col-12 text-center mx-auto">
                  <button
                    class="ms-md-3 edit-data-perfil-button"
                    :class="loading ? 'px-3' : ''"
                    @click="validateAndUpdatePasswords()"
                  >
                    <p v-if="!loading" class="mb-0 fs-6">Guardar</p>
                    <div v-else class="d-flex px-0 mx-0">
                      Cargando...
                      <div class="loader" />
                    </div>
                  </button>
                </div>
                <div v-if="errorUpdatePassPerfil" class="msg-error-perfil">
                  <p>{{ msgErrorUpdatePassPerfil }}</p>
                </div>
                <div
                  v-if="updatePasswordalert"
                  class="password-updated text-center mt-4"
                >
                  ¡Contraseña actualizada!
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 container-news-latter">
            <div class="new-latter-profile-datos">
              <div class="header-news-latter-profile-datos d-none d-md-flex">
                <h5><strong>Exclusiva para ti</strong></h5>
                <p>(Cumpleaños)</p>
              </div>
              <div class="img-news-latter-profile-datos mt-5 mt-md-0">
                <img src="/assets/Banner_promo_movil.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="profile-mis-direcciones py-5">
          <div
            class="container-bag-empty"
            style="height: 50vh; background-color: white"
          >
            <div class="text-center">
              <h3>Aún no has iniciado sesión</h3>
              <p class="fs-6">
                Para poder acceder a este modulo primero debes iniciar sesión.
              </p>
              <div class="mt-4" @click="$bvModal.show('modal-login')">
                <button class="btn-primary px-4 fs-6">Iniciar sesión</button>
              </div>
            </div>
          </div>
        </div>
        <footer-Returns />
      </div>
    </div>
  </div>
</template>
<script>
import FooterReturns from '@/components/Returns/FooterReturns'
import BaseProfile from '@/components/Profile/BaseProfile'

import { mapState, mapActions } from 'vuex'
export default {
  components: { FooterReturns, BaseProfile },
  data() {
    return {
      token: null,
      validateUpdatePassword: false,
      validatePassword: false,
      /******************************************/
      loading: false,
      updatePasswordalert: false,
      passwordNow: '',
      newPassword: '',
      stepDataUser: 0,
      typePass: true,
      typePassConfirm: true,
    }
  },
  computed: {
    ...mapState('user', [
      'dataUser',
      'errorUpdatePerfil',
      'msgErrorUpdatePassPerfil',
      'errorUpdatePassPerfil',
    ]),
  },
  created() {
    this.token = window.localStorage.getItem('accessToken')
    if (this.$route.params.isRegister) {
      this.stepDataUser = 1
    }
  },
  methods: {
    ...mapActions('user', ['updatePerfil', 'updatePassword']),
    async validateAndUpdatePasswords() {
      console.log(9)
      if (this.newPassword.length >= 8 && this.passwordNow) {
        this.validateUpdatePassword = false
        this.validatePassword = false
        this.loading = true
        await this.updatePassword({
          passwordNow: this.passwordNow,
          newPassword: this.newPassword,
        }).then(async () => {
          if (!this.errorUpdatePassPerfil) {
            this.passwordNow = ''
            this.newPassword = ''
            this.stepDataUser = 0
            this.updatePasswordalert = true
            await setTimeout(() => {
              this.updatePasswordalert = false
            }, 2500)
          }
          this.loading = false
        })
      } else {
        this.validateUpdatePassword = !(this.newPassword.length >= 8)
        this.validatePassword = !this.passwordNow
      }
    },
  },
}
</script>
